<template>
  <div>
    <v-sheet outlined class="ma-2 pa-4 mb-2 d-flex align-center">
      <span class="primary--text font-weight-bold"> {{ selectedYear }}년</span>
      <span class="font-weight-bold mx-2">총 매출</span>
      <span class="text-h6 mx-4"> {{ $toComma(totalAmount) }} 원</span>
    </v-sheet>
    <!-- 본문 -->
    <v-sheet outlined class="pa-2 ma-2">
      <v-data-table :headers="tableHeaders" :items="items" hide-default-footer :items-per-page="-1" :loading="isLoading" loading-text="Loading... Please wait">
        <template v-slot:[`item.date`]="{ item }">
          <span>{{ dayjs(item.date).format("M") }}월</span>
        </template>
        <template v-slot:[`item.pay_amount`]="{ item }">
          <span>{{ $toComma(item.pay_amount) }}원</span>
        </template>
        <template v-slot:[`item.cancel_amount`]="{ item }">
          <span>{{ $toComma(item.cancel_amount) }}원</span>
        </template>
        <template v-slot:[`item.total_amount`]="{ item }">
          <span>{{ $toComma(item.pay_amount - item.cancel_amount) }}원</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn small depressed color="primary" outlined @click="openDetail(item)">세부내역서</v-btn>
        </template>
      </v-data-table>
    </v-sheet>
  </div>
</template>
<script>
import statusService from "@/api/stats-service"
export default {
  props: {
    selectedYear: {
      type: String,
      default: "2025",
    },
  },
  watch: {
    selectedYear: {
      immediate: true, // 컴포넌트가 생성될 때도 실행
      handler(newVal) {
        this.load()
      },
    },
  },
  data: () => ({
    isLoading: false,
    items: [],
    totalAmount: 0,
    tableHeaders: [
      { value: "date", text: "날짜" },
      { value: "pay_amount", text: "결제 금액", align: "right" },
      { value: "cancel_amount", text: "취소 금액", align: "right" },
      { value: "total_amount", text: "매출 합계", align: "right" },
      { text: "세부내역", value: "actions", width: "150px", sortable: false },
    ],
  }),
  
  mounted() {
    this.load()
  },

  methods: {
    async load() {
      try {
        this.isLoading = true
        var from = this.dayjs().year(this.selectedYear).startOf("year")
        var to = this.dayjs().year(this.selectedYear).endOf("year")

        if (this.dayjs().year() == this.selectedYear) {
          from = this.dayjs().startOf("year")
          to = this.dayjs()
        } 

        const payInfos = await statusService.getSaleStatsV2(true, from.format("YYYY-MM-DD"), to.format("YYYY-MM-DD"))

        //const cancelInfos = await statusService.getSaleStats(true, from.format("YYYY-MM-DD"), to.format("YYYY-MM-DD"))

        //const result = this._getStructItems(from, to, payInfos, cancelInfos)
        this.items = payInfos
        this.totalAmount = this.items.reduce((sum, e) => sum + Number(e.total_amount), 0)
      } catch (e) {
        console.log(e)
        alert("데이터를 가져오지 못했습니다.")
      } finally {
        this.isLoading = false
      }
    },

    openDetail(item) {
      this.$emit("open", item)
    },
    _getStructItems(from, to, payInfos, cancelInfos) {
      const diffDay = to.diff(from, "month")

      return [...Array(diffDay + 1).keys()].map((e) => {
        const date = from.add(e, "month")
        const cancelInfo = cancelInfos.find((element) => element.date === date.format("YYYY-MM")) || {
          cancel_amount: 0,
          total_amount: 0,
        }
        const infoValue = payInfos.find((element) => element.date === date.format("YYYY-MM"))

        let cancel_amount = cancelInfo.cancel_amount
        let pay_amount = infoValue ? Number(infoValue.pay_amount) : 0
        let total_amount = pay_amount - cancel_amount

        return {
          date,
          cancel_amount,
          pay_amount,
          total_amount,
        }
      })
    },
  },
}
</script>
<style></style>
