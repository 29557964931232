<template>
    <div class="icon-button" @click="$emit('click')" :class="{ active: isActive }">
        <div class="icon-container">
            <img :src="iconSrc" :alt="text">
        </div>
        <div class="button-text" :class="{ 'light-text': isLight }">
            {{ text }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'IconButton',
    props: {
        iconSrc: {
            type: String,
            required: true
        },
        text: {
            type: String,
            required: true
        },
        isActive: {
            type: Boolean,
            default: false
        },
        isLight: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style scoped>
.icon-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    min-width: 40px;
    padding: 2px 2px;
    transition: all 0.2s ease;
}

.icon-container {
    width: 40px;
    height: 40px;
    margin-bottom: -2px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.button-text {
    color: white;
    font-size: 14px;
    text-align: center;
    white-space: nowrap;
}

.light-text {
    color: black;
}

.icon-button:hover {
    transform: translateY(-2px);
}

.icon-button.active {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
}
</style> 