<template>
  <div class="pb-16">
    <!-- 본문 -->
    <v-sheet class="pt-2 pt-md-4 mx-auto">
      <!-- 안내 문구 , 방문 후, 원본을 확인하세요. -->
       <v-sheet class="text-center">
        <p class="accent--text text-center mb-2 font-danjunghae" style="font-size: 24px" :lang="lang">
          {{ $t("page.mypage.reserv.visit-after-guide") }}
        </p>
        <div class="text-body-2 primary--text">{{ $t("page.mypage.reserv.original-delete-guide") }}</div>
        <div class="text-body-2" v-html="$t('page.mypage.reserv.delete-period-guide')" style="color: #535353"></div>
       </v-sheet>
      

      <!-- 예약 내역 없을 시 -->
      <v-sheet v-if="!list.length" class="rounded-10 pt-8 text-center text-subtitle-1 primary--text">
        <v-icon size="40" color="primary" class="mb-2">mdi-cancel</v-icon><br />
        {{ $t("page.mypage.reserv.empty") }}
      </v-sheet>

      <!-- 예약 내역 있을 시 -->
      <v-sheet v-if="list.length" class="px-2 text-subtitle-1 primary--text mt-4 mt-md-6">
        <v-sheet v-for="item in list" :key="item.id" class="mb-8 pa-4 pa-lg-5 py-6" style="cursor: pointer; background: #fffbf2; position: relative; border: 5px solid #f5e4e4; border-radius: 16px">          
          <div class="d-flex flex-column">
            <div class="d-flex justify-space-between align-center" :class="[$vuetify.breakpoint.mobile ? 'card-small' : 'card-normal']" style="margin-top: -10px">
              <span class="accent--text font-danjunghae" :style="{ fontSize: $vuetify.breakpoint.mobile ? '24px' : '28px' }" :lang="lang">{{ getTicketTitle(item) }}</span>
              <!-- <MagicCodes :numbers="item.r_code" /> -->
            </div>
          </div>

          <div class="mt-2" style="color: rgb(84, 84, 84); font-size: 16px; line-height: 1.1">
            <ul class="font-weight-light text-body-2 text-md-body-1" style="line-height: 18px">
              <li>{{ $t("page.mypage.reserv.entrance-time") + item.year + "." + item.month + "." + item.day + " / " + item.times }}</li>
              <li>
                <span>{{ $t("page.mypage.reserv.shooting-time") }} </span>
                <span class="primary--text">
                  {{ item.year + "." + item.month + "." + item.day + " / " + getShootingTime(item.times) }}
                </span>
              </li>
              <li>{{ item.concept }}</li>
              <li>{{ $t("page.mypage.reserv.person-count", { count: getPersonCount(item) }) }}</li>
            </ul>
          </div>
          <!--  버튼 -->
          <div class="rightButtonArea d-flex flex-column" style="gap: 4px">
            <!-- 미방문 버튼-->
             <v-btn
              depressed
              class="checkPictureButton rounded-lg text-body-1" 
              @click="onClickCheckOriginal(item)" 
              :width="$vuetify.breakpoint.mobile ? 80 : 140"
              :height="$vuetify.breakpoint.mobile ? 50 : 60"              
              :disabled="!item.hasMyShots || item.isOver"
            >
              {{ item.hasMyShots ? (item.isOver ? $t("page.mypage.shot-list.shot-image-select-date-over") : $t("button.check-original")) : $t("button.unvisited") }}
            </v-btn>

            <!-- 리뷰 버튼-->
            <v-btn 
              depressed 
              class="rightButtonStyle rounded-lg" 
              @click="openCautionDialog()" 
              :width="$vuetify.breakpoint.mobile ? 80 : 140"
              :height="$vuetify.breakpoint.mobile ? 40 : 52"
            >
              <div 
                class="d-flex flex-column align-center text-body-2 text-md-body-1 py-2" 
                :style="{ 
                  lineHeight: '1.0'
                }"
              >
                <span>{{ $t("button.review-notice-1") }}</span>
                <span>{{ $t("button.review-notice-2") }}</span>
              </div>
            </v-btn>

            <v-btn
              v-if="!isCantReserveCancel(item.created, item.year, item.month, item.day, item.times.split(',')[0])"
              depressed
              class="rightButtonStyle rounded-lg text-body-2 text-md-body-1"
              @click="reservationCancelOpen(item)"
              :width="$vuetify.breakpoint.mobile ? 80 : 140"
            >
              {{ $t("button.cancel-reserve") }}
            </v-btn>
          </div>
        </v-sheet>
      </v-sheet>
    </v-sheet>

    <!-- 리뷰쓰기 -->
    <v-dialog v-model="dialog.review" max-width="440" content-class="rounded-xl">
      <v-sheet class="px-5 py-6">
        <!-- 제목 -->
        <p class="mb-6 font-weight-bold text-center" style="font-size: 22px">리뷰 쓰기</p>

        <v-file-input placeholder="이미지 또는 동영상" outlined dense show-size hide-details prepend-icon color="primary" class="rounded-10" v-model="uploadImage">
          <template v-slot:prepend-inner>
            <v-icon class="mr-1" color="primary">mdi-image-filter-hdr</v-icon>
          </template>
        </v-file-input>

        <v-textarea class="mt-2 mb-5 rounded-10" outlined hide-details placeholder="리뷰 내용을 작성해주세요" v-model="review_content" rows="4"></v-textarea>

        <!-- 버튼 -->
        <div class="d-flex justify-center">
          <!-- 닫기 -->
          <v-btn width="120" depressed color="grey lighten-3" class="font-weight-bold rounded-10 mx-2" @click="dialog.review = false">{{ $t("button.close") }}</v-btn>

          <!-- 작성 -->
          <v-btn width="120" depressed color="primary lighten-1" class="font-weight-bold rounded-10 mx-2" @click="reviewSubmit()"> 작성 </v-btn>
        </div>
      </v-sheet>
    </v-dialog>

    <!-- 예약취소 -->
    <v-dialog v-model="dialog.cancel" max-width="440" content-class="rounded-xl" :key="cancelComponentKey">
      <v-sheet class="rounded-10 px-2 px-md-4 py-4 py-md-10 d-flex flex-column align-center" color="#f7f2f7">
        <img src="@/assets/warning.png" class="icon" />

        <p class="text-h5 grey--text text--darken-2 font-weight-bold mt-2">{{ $t("button.cancel-reserve") }}</p>

        <p class="text-center px-1 text-body-1" v-html="getRefundDialogMessage(selectedItem)"></p>

        <v-container fluid class="d-flex justify-center mt-6">
          <v-btn color="white" class="dialog_btn rounded-2 mr-2 grey--text" width="35%" style="font-size: 20px" depressed @click="dialog.cancel = false" large>{{ $t("button.no") }}</v-btn>

          <v-btn
            color="#d9bddd"
            class="dialog_btn rounded-2 ml-2"
            style="font-size: 20px"
            width="35%"
            depressed
            @click="reservationCancelSubmit(selectedItem)"
            large
            :loading="reservation_canceling"
            >{{ $t("button.yes") }}</v-btn
          >
        </v-container>
      </v-sheet>
      <!-- <v-sheet class="px-3 py-6">        
        <p class="mb-6 font-weight-bold text-center" style="font-size: 22px">예약 취소</p>

        <div v-if="hoursDifference(selectedItem.created) > 24" class="py-2">
          <div v-if="selectedItem.category == '라피아캐슬'">
            <p v-if="isWithinDiffHours(selectedItem.year, selectedItem.month, selectedItem.day, Number(selectedItem.times.substring(0, 2)), 72)" class="text-body-2 text-center mb-10">
              촬영 예정일 3일(72시간) 이내이므로<br />
              (1인당) 9,900원을 제외한 금액만<br />
              환불 처리됩니다. <br /><br />
              예약을 취소 하시겠습니까?
            </p>

            <p v-else class="text-body-2 text-center mb-10">
              예약 취소 후 해당 시간에 재 예약이<br />
              안될 수 있습니다.<br /><br />
              예약을 취소 하시겠습니까?
            </p>
          </div>
        </div>

        <div v-else>
          <p class="text-body-2 text-center mb-8">
            예약 결제 후 24시간 이내이므로<br />
            전액 환불 처리 됩니다.<br /><br />
            예약을 취소 하시겠습니까?
          </p>
        </div>

        
        <div class="d-flex justify-center">          
          <v-btn width="120" depressed color="grey lighten-3" class="font-weight-bold rounded-10 mx-2" @click="dialog.cancel = false"> 아니오 </v-btn>

          <v-btn
            v-if="selectedItem.category == '라피아캐슬' && isCanRefund(selectedItem)"
            width="110"
            depressed
            color="primary lighten-1"
            class="font-weight-bold rounded-10 mx-1"
            @click="reservationCancelSubmit(selectedItem)"
          >
            예
          </v-btn>
        </div>
      </v-sheet> -->
    </v-dialog>
    <CautionMesageDialog
      :readonly="true"
      @agree="show_refund_policy = false"
      @cancel="show_refund_policy = false"
      @input="(e) => (show_refund_policy = e)"
      :visible="show_refund_policy"
      :key="'refund_policy_dialog_' + refund_policy_dialog_componentKey"
    />
  </div>
</template>
<script>
import { mapState } from "vuex"
import MagicCodes from "./MagicCodes"
import payletterService from "@/api/payletter-service"
import CautionMesageDialog from "@/views/components/CautionMesageDialog"
import { SHOOTING_TIME } from "@/constant"

export default {
  components: {
    MagicCodes,
    CautionMesageDialog,
  },
  data: () => ({
    // 기본 정보 > 썸네일 이미지
    uploadImage: {},
    url: "",

    list: [],

    selectedItem: {},

    review_content: "",

    dialog: {
      cancel: false,
      review: false,
    },
    cancelComponentKey: 0,
    reservation_canceling: false,

    show_refund_policy: false,
    refund_policy_dialog_componentKey: 0,
  }),

  mounted() {
    // 예약 목록 불러오기
    this.load()
  },

  computed: {
    ...mapState(["lang"]),
  },

  methods: {
    // 예약 목록 불러오기
    load() {
      this.$http
        .post("/api/order/select/specific/user", {
          params: {
            user_id: this.$store.state.lafia_user.user_id,
          },
        })
        .then(async (res) => {
          
          this.list = await Promise.all(res.data.map(async (item) => {
            try {
              const { hasMyShots, isOver,qrcodeId } = await this.getHasMyShotsByReservation(item.reservation_id)
              item.hasMyShots = hasMyShots
              item.isOver = isOver
              item.qrcodeId = qrcodeId
            }catch(error) {
              console.log(error)
              item.hasMyShots = false
            }
            console.log(item)
            return item
          }))
        })
    },

    // 리뷰쓰기 열기
    reviewOpen(item) {
      this.dialog.review = true
      this.selectedItem = item
    },

    // 리뷰쓰기 제출
    reviewSubmit: _.debounce(async function () {
      var file = this.uploadImage
      this.url = URL.createObjectURL(file)
      var formData = new FormData()
      formData.append("image", file)

      // 이미지 업로드
      await this.$http
        .post("/api/image/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          params: {
            type: "review",
          },
        })
        .then((res) => {
          // DB 저장
          this.$http
            .post("/api/review/insert", {
              params: {
                user_id: this.$store.state.lafia_user.user_id,
                room_id: this.selectedItem.room_id,
                order_id: this.selectedItem.order_id,
                image: res.data,
                content: this.review_content,
              },
            })
            .then((res) => {
              if (res.data.affectedRows) {
                this.$http
                  .post("/api/order/update/status", {
                    params: {
                      status: "리뷰작성완료",
                      id: this.selectedItem.order_id,
                    },
                  })
                  .then((res) => {
                    if (res.data.affectedRows) {
                      this.load()
                      alert("리뷰가 작성되었습니다.")
                      this.dialog.review = false
                    }
                  })
              }
            })
        })
    }, 500),

    // 예약취소 열기
    reservationCancelOpen(item) {
      this.cancelComponentKey++
      this.selectedItem = item
      this.dialog.cancel = true
    },

    // 예약취소 제출
    async reservationCancelSubmit(item) {
      // console.log(item)
      // console.log(this.hoursDifference(item.created))

      if (this.isDatePassed(item.year, item.month, item.day, item.times.split(",")[0])) {
        alert(this.$t("alert.error_reservation_cancel_failed"))
        return
      }

      const canAmount = this.getCanCancelAmount(item)
      if (canAmount < 0) {
        alert("금액이 잘못되었습니다. 관리자에게 문의하세요!")
        return
      }

      try {
        this.reservation_canceling = true
        // 시간 체크
        /*
        if (canAmount === 0 && !item.paymentKey) {
          const response = await this.$http.post("/api/order/update/status", {
            params: {
              id: item.id,
              status: "취소완료",
            },
          })
          if (response.data.affectedRows) {
            alert("예약이 정상적으로 취소되었습니다.")
            this.load()
            this.dialog.cancel = false
          }
          this.$http
            .post("/api/coupon/cancelUseCoupon", {
              toss_id: item.toss_id.toString(),
              user_id: this.$store.state.lafia_user.user_id,
            })
            .then((res) => {
              console.log("쿠폰이 환불처리되었습니다.")
            })
        } else { */

        let params = {
          orderListId: item.id,
          paymentKey: item.paymentKey,
          cancelAmount: canAmount,
          cancelReason: "사용자 취소",
          isAdmin: false,
          isAll: item.pay_amount === canAmount,
        }

        console.log("cancel amount", params)

        const orderFunc = (params) => (item.payment_type === "payletter" ? payletterService.cancelOrder(params) : this.$http.post("/api/payments/toss/order/cancel", params))

        const response = await orderFunc(params)
        /*
        const response = await this.$http.post("/api/payments/toss/order/cancel", {
          orderListId: item.id,
          paymentKey: item.paymentKey,
          cancelAmount: canAmount,
          cancelReason: "사용자 취소",
          isAdmin: false,
          isAll: item.pay_amount === canAmount,
        }) */

        //if (response.data.orderId) {
        this.$http.post("/api/coupon/cancelUseCoupon", {
          toss_id: item.toss_id.toString(),
          user_id: this.$store.state.lafia_user.user_id,
        })

        alert(this.$t("alert.success_to_cancel_reservation"))
        this.load()
        this.dialog.cancel = false
        //}
        //}
      } catch (e) {
        console.log(e)
        //code: ALREADY_CANCELED_PAYMENT 이미 취소된 결제
        if (e.response.data && e.response.data.message) {
          // 이미 취소된 결제 입니다.
          if (e.response.data.code === "ALREADY_CANCELED_PAYMENT") {
            //TODO:: DB cancel. how?
          }
          alert(this.$t("alert.error_contact_mananger_with_message", { message: e.response.data.message }))
        } else {
          alert(this.$t("alert.fail_to_cancel_reservation"))
        }
        this.$router.go(0)
      } finally {
        this.reservation_canceling = false
      }
    },

    // 예약취소 제출2 (라피아캐슬 > 전체환불)
    reservationCancelSubmit2(item) {
      this.$http
        .post("/api/order/update/status", {
          params: {
            id: item.id,
            status: "취소",
          },
        })
        .then((res) => {
          if (res.data.affectedRows) {
            alert(this.$t("alert.success_to_cancel_reservation"))
            this.load()
            this.dialog.cancel = false
          }
        })
    },

    //
    isCantReserveCancel(created, year, month, day, time) {
      return !this.isWithin24Hours(created) && (this.isDatePassed(year, month, day, time) || this.getDiffDays(year, month, day) <= 3)
    },

    // 현재 시각을 지났는지 체크
    isDatePassed(year, month, day, time) {
      console.log(year, month, day, time)
      let inputDate = new Date(year, month - 1, day, ...time.split(":"))
      return inputDate < new Date()
    },

    hoursDifference(created) {
      const target = this.$DateTime.fromISO(created)
      const now = this.$DateTime.now()
      const diff = now.diff(target).as("hours")
      console.log(Math.ceil(Math.abs(diff)), target, now, diff)
      return Math.ceil(Math.abs(diff))
    },

    // X일 이내 체크용 -예약일 기준으로
    isWithinDiffHours(year, month, day, hour, diff) {
      const difference = this.getDiffHours(year, month, day, hour)
      return Math.ceil(Math.abs(difference)) <= diff
    },

    getDiffHours(year, month, day, hour) {
      const targetDate = this.$DateTime.local(year, month, day, hour)
      console.log("isWithinDiffHours")
      console.log("예약일시", targetDate.toFormat("yyyy-MM-DD HH mm"))
      const now = this.$DateTime.now()
      const difference = now.diff(targetDate).as("hours")
      console.log("difference", difference)
      return difference
    },

    getDiffDays(year, month, day) {
      const targetDate = this.$DateTime.local(year, month, day).startOf("day")
      const now = this.$DateTime.now().startOf("day")
      const difference = now.diff(targetDate).as("days")
      console.log("getDiffDays difference", difference)
      return Math.floor(Math.abs(difference))
    },

    isWithin24Hours(created) {
      const target = this.$DateTime.fromISO(created)
      const now = this.$DateTime.now()

      const diff = now.diff(target).as("hours")
      return Math.abs(diff) <= 24
    },

    getCanCancelAmount(item) {
      console.log(item.times)
      const diffDay = this.getDiffDays(item.year, item.month, item.day)
      if (this.isDatePassed(item.year, item.month, item.day, item.times.split(",")[0])) {
        // 환불 불가
        return -1
      } else if (this.isWithin24Hours(item.created)) {
        // 24시간 이내 전액 환불
        return item.pay_amount
      } else if (diffDay <= 3) {
        return -1
      } else if (diffDay <= 6) {
        // 4 ~ 6
        const rate = [0.3, 0.5, 0.8]
        return item.pay_amount * rate[diffDay - 4]
      } else {
        // 전액 환불
        return item.pay_amount
      }
    },

    getTicketTitle(item) {
      switch (item.memo) {
        case "option1":
          return this.$t("page.reservation.lafia-option-item-a-Title")
        case "option2":
          return this.$t("page.reservation.lafia-option-item-b-Title")
        case "option3":
          return this.$t("page.reservation.lafia-option-item-c-Title")
        case "option4":
          return this.$t("page.reservation.lafia-option-item-d-Title")
        case "option5":
          return this.$t("page.reservation.lafia-option-item-e-Title")
        case "option6":
          return this.$t("page.reservation.lafia-option-item-f-Title")
        default:
          return this.$t("page.mypage.reserv.ticket-title")
      }
    },
    getPersonCount(item) {
      switch(item.memo) {
        case "option1":
          return 1
        case "option2":
          return 1
        case "option3":
          return item.cloth
        case "option4":
          return 2
        case "option5":
          return 3
        case "option6":
          return 4
      }
    },

    getRefundDialogMessage(item) {
      const { year, month, day, created } = item
      const diffDay = this.getDiffDays(year, month, day)

      if (this.isWithin24Hours(created)) {
        return this.$t("dialog.reservation-cancel-confirm-24")
      } else if (diffDay <= 6) {
        // 4 ~ 6
        const rate = ["30", "50", "80"]
        return this.$t("dialog.reservation-cancel-confirm-day", { day: diffDay, percent: rate[diffDay - 4] })
      } else {
        return this.$t("dialog.reservation-cancel-confirm")
      }
    },

    getShootingTime(time) {
      console.log(time)
      const [hours, minutes] = time.split(":").map(Number)
      const totalMinutes = hours * 60 + minutes + SHOOTING_TIME
      const endHours = Math.floor(totalMinutes / 60)
      const endMinutes = totalMinutes % 60
      const shootingtime = `${String(endHours).padStart(2, "0")}:${String(endMinutes).padStart(2, "0")}`
      return shootingtime
    },

    openCautionDialog() {
      this.show_refund_policy = true
      this.refund_policy_dialog_componentKey++
    },

    // 예약 상세 > 촬영 이미지 존재 여부 체크
    async getHasMyShotsByReservation(reservation_id) {
      const response = await this.$http.post("/api/qrcode/select/byReservation", {
        params: {
          user_id: this.$store.state.lafia_user.user_id,
          reservation_id: reservation_id,
        },
      })
      const qrCodeItem = response.data[0];
      if(qrCodeItem) {
        const untilDate = new Date(this.getTDaysLater(qrCodeItem.created, 7))
        untilDate.setHours(23, 59, 59)
        const isOver = new Date() > untilDate        

        return {
          qrcodeId: qrCodeItem.id,
          hasMyShots: response.data.length > 0,
          isOver: isOver
        }
      } else {
        return {
          hasMyShots: false
        }
      }
    },

    onClickCheckOriginal(item) {
      console.log(item)
      if(item.qrcodeId) {
        this.$router.push(`/mypage/select?id=${item.qrcodeId}`)
      } else {
        alert(this.$t("alert.error_page"))
      }
    },

    getTDaysLater(date, days) {
      let d = new Date(date)
      d.setDate(d.getDate() + days)
      return d
    },
  },
}
</script>
<style scoped>
.rightButtonArea {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.rightButtonStyle {
  background-color: white !important;
  color: #625072 !important;
  font-weight: 500;
}


.checkPictureButton {
  background-color: #ffe5e5 !important;
  color: #c06895 !important;
  font-weight: 500;
}

.reservationCancel {
  background-color: white !important;
  color: #625072 !important;
  font-weight: 500;
  position: absolute;
  right: 10px;
  bottom: 10px;
}
.icon {
  width: 60px;
  height: 60px;
}
.dialog_btn {
  border: 2px solid #dadada !important;
  font-size: 16px;
}

.card-normal {
  height: 60px;
}
.card-small {
  height: 40px;
}
</style>
