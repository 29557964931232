<template>
  <v-dialog max-width="440" :value="visible" content-class="rounded-10" style="z-index: 15" @input="$emit('input', $event)">
    <v-card>
      <v-card-title> {{ $t("button.selectConcept") }} </v-card-title>
      <v-card-text>
        <v-list v-if="conceptList.length" two-line outlined style="min-height: 200px; max-height: 300px; overflow-y: auto; background-color: #fafafa">
          <v-list-item-group v-model="selectedConceptIdx" color="primary" mandatory>
            <v-list-item v-for="concept in conceptList" :key="concept.id">
              <div class="mr-3" style="width: 48px; height: 48px;">
                <v-img
                  :src="'/upload/concept/thumbnail_image/' + concept.pattern_1_image"
                  :aspect-ratio="1"
                  :lazy-src="require('@/assets/placeholder.jpg')"
                  class="rounded-0"
                  height="48"
                >
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </div>
              <v-list-item-content>
                <v-list-item-title>{{ getConceptTitle(concept) }}</v-list-item-title>
                <v-list-item-subtitle>{{ getConceptTagsStr(concept) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </v-list-item-group>
        </v-list>
        <v-sheet v-else>
          <span class="grey--text text-body-1"> 등록된 컨셉이 없습니다. </span>
        </v-sheet>
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-btn color="black" outlined class="dialog_btn rounded-2" style="font-size: 20px" width="35%" depressed @click="confirmClick" large> {{ $t("button.cancel") }} </v-btn>

        <v-btn color="#EF7FAB" class="dialog_btn rounded-2" style="font-size: 20px" width="35%" depressed @click="applyClick" large>
          {{ $t("button.apply") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex"
import { AllTag, Tags } from "@/constant"

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    }
  },
  inject: ["concepts"],
  computed: {
    ...mapState(["lang"]),
  },
  data: () => ({
    conceptList: [],
    selectedConceptIdx: 0,
  }),

  created() {
    this.conceptList = this.concepts
    if (this.title && this.conceptList.length > 0) {
      const idx = this.conceptList.findIndex(c => c.title === this.title)
      if (idx !== -1) {
        this.selectedConceptIdx = idx
      }
    }
  },

  methods: {
    ...mapActions(["loadConcepts"]),

    confirmClick() {
      this.$emit("close")
    },
    applyClick() {
      console.log(this.conceptList[this.selectedConceptIdx])
      this.$emit("apply", this.conceptList[this.selectedConceptIdx])
      this.$emit("close")
    },

    getConceptTitle(concept) {
      switch (this.lang) {
        case "en":
          return concept.title_en || concept.title
        case "jp":
          return concept.title_jp || concept.title
        default:
          return concept.title
      }
    },
    getConceptTagsStr(concept) {
      let tagStr = concept.tag
      if (!tagStr) {
        return ""
      }
      let tags = tagStr.split(",")
      let joinedStr = tags
        .map((tag) => Tags.find((t) => t.title === tag)?.id)
        .filter((e) => e)
        .map((tagKey) => this.$t(`tags.${tagKey}`))
        .join(",")
      return joinedStr
    },
  },
  async mounted() {
    console.log("ConceptSelectList mounted")
  },
}
</script>
<style scoped>
::v-deep .v-card {
  border: 3px solid #dadada !important;
  border-radius: 10px;
}
.dialog_btn {
  border: 2px solid #dadada !important;
  font-size: 16px;
}
</style>
