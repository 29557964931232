<template>
  <div>
    <!-- 제목 -->
    <v-sheet outlined class="d-flex align-center ma-2 px-4" height="60">
      <font class="font-weight-bold text-h6 grey--text text--darken-2">라피아 예약 스케줄</font>
    </v-sheet>

    <!-- 본문 -->
    <v-sheet class="ma-2 pa-4" outlined>
      <!-- 날짜 선택 -->
      <v-sheet class="d-flex justify-space-between align-center" style="min-width: 1030px; max-width: 1280px;">
        <DateSelector :color="$vuetify.theme.currentTheme.primary" :width="330" class="mt-1 mb-4"
          @dateUpdated="dateUpdated_handler" />
        <div class="d-flex align-center font-weight-bold text-darken-2"> 예약 수: {{ this.reservation_list.length }} 건</div>
      </v-sheet>

      <!-- 시간테이블 -->
      <table class="timeTable">
        <tr v-for="item in timeTable" :key="item.time">
          <!-- 시간 -->
          <td style="height: 110px; width: 100px; background: rgba(195, 126, 120, 0.15); text-align: center">
            <p class="mb-0">
              {{ item.time }}
            </p>
          </td>

          <!-- 예약이 없는 경우 -->
          <td v-if="!reservation_list.map((e) => e.time).includes(item.time)" style="height: 110px"
            :class="item.is_rest != '' ? 'rest' : item.is_rest != '' ? 'reservation' : ''" @click="openMemo(item)">
            <span v-if="!item.is_rest && item.memo">{{ item.memo }}</span>
            <span v-if="item.is_rest">휴식</span>
          </td>

          <!-- 예약이 있는 경우 -->
          <td v-else class="pa-0 pr-0" style="background: #f1f1f1">
            <div class="d-flex justify-center">
              <v-sheet v-for="reservation in reservation_list.filter((obj) => obj.time === item.time)"
                :key="reservation.id" height="110" width="200"
                class="rounded-10 primary lighten-1 white--text text-center mr-1 d-flex flex-column justify-center"
                :style="{ backgroundColor: getReservationStyle(reservation) }" @click="detailCastle(reservation)">
                <div class="d-flex align-center">
                  <v-img :src="getConceptImageSrc(reservation.concept)" height="110" width="80"
                    class="rounded-10 pa-2 "></v-img>
                  <div class="flex-grow-1 text-left ml-4">
                    <p class="text-body-2 mt-1 mb-0 text-truncate px-2"
                      :style="reservation.name == null ? 'color:red;' : ''" style="line-height: 16px">
                      {{ reservation.name ? reservation.name : reservation.user_id }}
                    </p>
                    <div class="text-caption font-weight-medium mt-2">{{ getTicketName(reservation) }}</div>
                    <div class="text-caption font-weight-bold">{{ reservation.concept }}</div>
                    <div class="text-caption font-weight-bold">촬영 : {{ getShootingTime(reservation.time) }}</div>
                  </div>
                </div>
              </v-sheet>
            </div>
          </td>
        </tr>
      </table>
    </v-sheet>

    <!-- 메모하기 (dialog) -->
    <v-dialog v-model="dialog.memo" width="440" content-class="rounded-xl">
      <v-sheet class="px-16 pt-8 pb-6">
        <!-- 제목 -->
        <p class="mb-6 font-weight-bold text-center" style="font-size: 24px">메모하기</p>

        <v-text-field class="rounded-15" outlined :disabled="selectedItem.is_rest" v-model="selectedItem.memo"
          counter="10"></v-text-field>
        <v-btn depressed class="rounded-15 d-block mx-auto px-16"
          :class="selectedItem.is_rest ? 'green white--text' : 'grey lighten-2'" @click="
            selectedItem.is_rest = !selectedItem.is_rest
          dialog.memo = false
            ">
          휴식
        </v-btn>

        <!-- 버튼 -->
        <div class="d-flex justify-center mt-4">
          <!-- 취소 -->
          <v-btn width="100" depressed color="grey lighten-3" class="font-weight-bold rounded-10 mr-3" @click="
            selectedItem.is_rest = false
          selectedItem.memo = ''
          dialog.memo = false
            ">
            비우기
          </v-btn>

          <!-- 제출 -->
          <v-btn width="100" dark depressed class="font-weight-bold rounded-10" @click="dialog.memo = false"> 입력
          </v-btn>
        </div>
      </v-sheet>
    </v-dialog>

    <!-- 예약 상세보기 (dialog) -->
    <v-dialog v-model="dialog.detail" width="840" content-class="rounded-xl">
      <v-sheet class="pt-8 pb-6 d-flex">
        <v-sheet width="460">
          <!-- 제목 -->
          <p class="mb-6 font-weight-bold text-center" style="font-size: 24px">예약 상세보기</p>

          <table class="table_detail mx-auto">
            <tr>
              <td>예약자명</td>
              <td>{{ selected_reservation.name }}</td>
            </tr>
            <tr>
              <td>전화번호</td>
              <td>{{ selected_reservation.phone }}</td>
            </tr>
            <tr>
              <td>이메일</td>
              <td>{{ selected_reservation.email }}</td>
            </tr>
            <tr style="height: 16px"></tr>
            <tr>
              <td>상품타입</td>
              <td>
                {{ getTicketName(selected_reservation) }}
                <!-- , 미대여 {{ selected_reservation.uncloth }}명 -->
              </td>
            </tr>
            <tr>
              <td>컨셉</td>
              <td>
                <div class="d-flex align-center w-100">
                  <span>{{ selected_reservation.concept }}</span>
                  <v-btn @click="openSelectConcept()" class="ml-4 rounded-lg" depressed>컨셉 변경</v-btn>
                </div>
              </td>
            </tr>
            <tr>
              <td>결제일</td>
              <td>{{ new Date(selected_reservation.created).toLocaleDateString() }}</td>
            </tr>
            <tr>
              <td>이용일</td>
              <td>{{ selected_reservation.year }}.{{ selected_reservation.month }}.{{ selected_reservation.day }} / {{
                selected_reservation.time }}</td>
            </tr>
            <tr style="height: 16px"></tr>
            <tr>
              <td>상태</td>
              <td>{{ selected_reservation.status }}</td>
            </tr>

            <tr>
              <td>결제금액</td>
              <td>{{ $toComma(selected_reservation.pay_amount || 0) }}원</td>
            </tr>
            <tr v-if="selected_reservation.toss_id > 0">
              <td>취소가능금액</td>
              <td>{{ selected_reservation.type && selected_reservation.type.startsWith("현장") ? "현장결제" :
                $toComma(selected_reservation.balance_amount || 0) + "원" }}</td>
            </tr>
            <tr v-else>
              <td class="text-center mx-auto" colspan="2">현장 결제 처리</td>
            </tr>
            <tr>
              <td>프로모션</td>
              <td>{{ selected_reservation.promotionCode ? getPromotionName(selected_reservation.promotionCode) : "-" }}
              </td>
            </tr>

            <!-- <tr style="height:16px;"></tr>
                    <tr>
                        <td>이용이력</td>
                        <td>없음</td>
                    </tr> -->
          </table>

          <!-- 버튼 -->
          <div class="d-flex justify-center mt-8">
            <!-- 취소 -->
            <v-btn width="100" depressed color="grey lighten-3" class="font-weight-bold rounded-10 mr-3"
              @click="dialog.detail = false"> 닫기 </v-btn>

            <!-- 제출 -->
            <v-btn width="100" dark depressed class="font-weight-bold rounded-10" @click="reservation_cancel()"
              :loading="reservation_canceling" :disabled="reservation_cancel_disabled">
              예약취소
            </v-btn>
          </div>
        </v-sheet>
        <v-sheet class="flex-grow-1 px-8">
          <v-sheet class="flex-column" height="100%">
            <!-- 제목 -->
            <p class="mb-2 font-weight-medium text-center" style="font-size: 20px">메모</p>

            <v-textarea class="rounded-15 flex-grow-1" outlined v-model="selected_reservation.memo" counter="512"
              maxlength="512" height="150"></v-textarea>

            <div class="d-flex justify-end mt-2">
              <v-btn depressed class="rounded-lg" width="80" @click="saveReservationMemo">메모 저장</v-btn>
            </div>

            <p class="mb-2 font-weight-medium text-center" style="font-size: 20px">촬영사진</p>
            <QRCodeGallery :selected_reservation="selected_reservation" />
          </v-sheet>
        </v-sheet>
      </v-sheet>
    </v-dialog>
    <ConceptSelectDialog :visible="show_concept_select" :title="selected_reservation.concept"
      @close="show_concept_select = false" @input="(e) => (show_concept_select = e)"
      :key="'concept' + show_concept_select_componentKey" @apply="changeConcept" />

  </div>
</template>
<script>
import { computed } from "vue"
import DateSelector from "@/components/DateSelector"
import payletterService from "@/api/payletter-service"
import { mapActions } from "vuex"
import { SHOOTING_TIME } from "@/constant"
import ConceptSelectDialog from "@/views/components/ConceptSelectDialog"
import QRCodeGallery from '@/views/admin/qrcode/QRCodeGallery'

export default {
  components: {
    DateSelector,
    ConceptSelectDialog,
    QRCodeGallery,
  },
  provide() {
    return {
      concepts: computed(() => this.concepts),
    }
  },

  data: () => ({
    date: null,

    timeTable: [
      { time: "10:00", is_reservation: false, memo: "", is_rest: false },
      { time: "10:15", is_reservation: false, memo: "", is_rest: false },
      { time: "10:30", is_reservation: false, memo: "", is_rest: false },
      { time: "10:45", is_reservation: false, memo: "", is_rest: false },
      { time: "11:00", is_reservation: false, memo: "", is_rest: false },
      { time: "11:15", is_reservation: false, memo: "", is_rest: false },
      { time: "11:30", is_reservation: false, memo: "", is_rest: false },
      { time: "11:45", is_reservation: false, memo: "", is_rest: false },
      { time: "12:00", is_reservation: false, memo: "", is_rest: false },
      { time: "12:15", is_reservation: false, memo: "", is_rest: false },
      { time: "12:30", is_reservation: false, memo: "", is_rest: false },
      { time: "12:45", is_reservation: false, memo: "", is_rest: false },
      { time: "13:00", is_reservation: false, memo: "", is_rest: false },
      { time: "13:15", is_reservation: false, memo: "", is_rest: false },
      { time: "13:30", is_reservation: false, memo: "", is_rest: false },
      { time: "13:45", is_reservation: false, memo: "", is_rest: false },
      { time: "14:00", is_reservation: false, memo: "", is_rest: false },
      { time: "14:15", is_reservation: false, memo: "", is_rest: false },
      { time: "14:30", is_reservation: false, memo: "", is_rest: false },
      { time: "14:45", is_reservation: false, memo: "", is_rest: false },
      { time: "15:00", is_reservation: false, memo: "", is_rest: false },
      { time: "15:15", is_reservation: false, memo: "", is_rest: false },
      { time: "15:30", is_reservation: false, memo: "", is_rest: false },
      { time: "15:45", is_reservation: false, memo: "", is_rest: false },
      { time: "16:00", is_reservation: false, memo: "", is_rest: false },
      { time: "16:15", is_reservation: false, memo: "", is_rest: false },
      { time: "16:30", is_reservation: false, memo: "", is_rest: false },
      { time: "16:45", is_reservation: false, memo: "", is_rest: false },
      { time: "17:00", is_reservation: false, memo: "", is_rest: false },
      { time: "17:15", is_reservation: false, memo: "", is_rest: false },
      { time: "17:30", is_reservation: false, memo: "", is_rest: false },
      { time: "17:45", is_reservation: false, memo: "", is_rest: false },
      { time: "18:00", is_reservation: false, memo: "", is_rest: false },
      { time: "18:15", is_reservation: false, memo: "", is_rest: false },
      { time: "18:30", is_reservation: false, memo: "", is_rest: false },
      { time: "18:45", is_reservation: false, memo: "", is_rest: false },
      { time: "19:00", is_reservation: false, memo: "", is_rest: false },
      { time: "19:15", is_reservation: false, memo: "", is_rest: false },
      { time: "19:30", is_reservation: false, memo: "", is_rest: false },
      { time: "19:45", is_reservation: false, memo: "", is_rest: false },
      { time: "20:00", is_reservation: false, memo: "", is_rest: false },
      { time: "20:15", is_reservation: false, memo: "", is_rest: false },
      { time: "20:30", is_reservation: false, memo: "", is_rest: false },
      { time: "20:45", is_reservation: false, memo: "", is_rest: false },
      { time: "21:00", is_reservation: false, memo: "", is_rest: false },
      { time: "21:15", is_reservation: false, memo: "", is_rest: false },
      { time: "21:30", is_reservation: false, memo: "", is_rest: false },
      { time: "21:45", is_reservation: false, memo: "", is_rest: false },
      { time: "22:00", is_reservation: false, memo: "", is_rest: false },
    ],

    selectedItem: {},
    concepts: [],

    reservation_list: [
      {
        time: "",
        name: "",
      },
    ],

    selected_reservation: {},

    promotions: [],

    dialog: {
      memo: false,
      detail: false,
    },

    reservation_canceling: false, // 예약 취소 진행중

    show_concept_select: false,
    show_concept_select_componentKey: 0,
  }),

  async mounted() {
    try {
      this.concepts = await this.loadConceptsAll()
    } catch (e) { }
  },

  watch: {
    date() {
      // 라피아캐슬 예약 불러오기
      this.loadCastleTime()
    },
  },

  computed: {
    reservation_cancel_disabled() {
      console.log(this.selected_reservation)
      return this.selected_reservation.toss_id <= 0
    },
  },

  methods: {
    ...mapActions(["loadConceptsAll"]),
    // DateSelector에서 date 
    dateUpdated_handler(date) {
      this.date = date
    },

    // 라피아캐슬 예약 불러오기
    loadCastleTime() {
      this.loadPriceInfo().then((res) => {
        this.$http
          .post("/api/reservation/select/specific/day/category/all", {
            params: {
              category: "라피아캐슬",
              year: this.date.year(),
              month: this.date.month() + 1,
              day: this.date.date(),
            },
          })
          .then((res) => {
            console.log(this.date.format("YYYY년 MM월 DD일"))
            console.log("예약 목록:", res.data)
            this.reservation_list = res.data
          })
      })
    },

    openMemo(item) {
      this.selectedItem = item
      this.dialog.memo = true
    },

    // 주문 상세
    detailCastle(reservation) {
      this.selected_reservation = { ...reservation }
      this.dialog.detail = true
    },

    isOnlineReservation(reservation) {
      if (!reservation.type) return true
      return !reservation.type.startsWith("현장")
    },
    ///:style="'{ background-color: `${getReservationColor(reservation)}` }'"
    getReservationStyle(reservation) {
      if (reservation.promotionCode && this.hasPromotion(reservation.promotionCode)) {
        return "#865491 !important"
      } else if (this.isOnlineReservation(reservation)) {
        return "#a26867 !important"
      } else {
        return "#54916c !important"
      }
    },

    async loadPriceInfo() {
      try {
        const result = await this.$http.post("/api/promotion/select/search")
        this.promotions = result.data.filter((e) => e.role !== "TRAVELER" && !e.code.startsWith("default"))
        //console.log(this.promotions)
      } catch (e) {
        console.log(e)
        this.promotions = []
      }
    },

    hasPromotion(promotionCode) {
      return this.promotions.find((p) => p.code == promotionCode)
    },

    getPromotionName(promotionCode) {
      const promotion = this.promotions.find((p) => p.code == promotionCode)
      return promotion && promotion.name ? promotion.name : promotionCode
    },

    // 예약 취소하기
    reservation_cancel: _.debounce(async function () {
      if (!this.selected_reservation) {
        alert("선택한 항목이 없습니다.")
        return
      }

      if (this.selected_reservation.type === "현장예약") {
        // 스케줄만 취소
        if (confirm("현장 결제한 사항입니다. 스케줄을 취소하시겠습니까?")) {
          const response = await this.$http.post("/api/order/update/status", {
            params: {
              id: this.selected_reservation.order_id,
              status: "취소완료",
            },
          })
          if (response.data.affectedRows) {
            alert("예약이 정상적으로 취소되었습니다.")
            this.loadCastleTime()
            this.dialog.detail = false
          }
        }
        return
      }

      let confirmMessage = `해당 ${this.selected_reservation.name} 의 ${this.selected_reservation.month}/${this.selected_reservation.day} ${this.selected_reservation.time} 예약을 정말로 취소하시겠습니까? 취소금액은 ${this.selected_reservation.balance_amount} 입니다.`

      if (confirm(confirmMessage)) {
        try {
          this.reservation_canceling = true
          /*
          if (this.selected_reservation.balance_amount === 0 && !this.selected_reservation.paymentKey) {
            const response = await this.$http.post("/api/order/update/status", {
              params: {
                id: this.selected_reservation.order_id,
                status: "취소완료",
              },
            })
            if (response.data.affectedRows) {
              alert("예약이 정상적으로 취소되었습니다.")
              this.loadCastleTime()
              this.dialog.detail = false

              this.$http
                .post("/api/coupon/cancelUseCoupon", {
                  toss_id: this.selected_reservation.toss_id.toString(),
                  user_id: this.selected_reservation.user_id,
                })
                .then((res) => {
                  console.log("쿠폰이 환불처리되었습니다.")
                })
            }
          } else { */
          // if (!this.selected_reservation.balance_amount) {
          //   alert("취소 가능한 금액이 없습니다.")
          //   return
          // }
          let params = {
            orderListId: this.selected_reservation.order_id,
            paymentKey: this.selected_reservation.paymentKey,
            cancelAmount: this.selected_reservation.balance_amount,
            cancelReason: "관리자 취소",
            isAdmin: true,
            isAll: true,
            adminId: this.$store.state.lafia_admin.user_id,
          }

          const orderFunc = (params) => (this.selected_reservation.payment_type === "payletter" ? payletterService.cancelOrder(params) : this.$http.post("/api/payments/toss/order/cancel", params))

          const response = await orderFunc(params)

          /*
          const response = await this.$http.post("/api/payments/toss/order/cancel", {
            orderListId: this.selected_reservation.order_id,
            paymentKey: this.selected_reservation.paymentKey,
            cancelAmount: this.selected_reservation.balance_amount,
            cancelReason: "관리자 취소",
            isAdmin: true,
            isAll: true,
            adminId: this.$store.state.lafia_admin.user_id,
          }) */
          //if (response.data.orderId) {
          alert("예약이 정상적으로 취소되었습니다.")
          this.loadCastleTime()
          this.dialog.detail = false

          this.$http.post("/api/coupon/cancelUseCoupon", {
            toss_id: this.selected_reservation.toss_id.toString(),
            user_id: this.selected_reservation.user_id,
          })
          //}
          //}
        } catch (e) {
          console.log(e)
        } finally {
          this.reservation_canceling = false
        }
      }
    }, 500),

    saveReservationMemo: _.debounce(function () {
      this.$http
        .post("/api/reservation/update/memo", {
          memo: this.selected_reservation.memo ?? "",
          id: this.selected_reservation.id,
        })
        .then((res) => {
          if (res.data.affectedRows) {
            alert("저장하였습니다.")
          } else {
            alert("저장에 실패하였습니다.")
          }
        })
        .catch((e) => {
          console.log(e)
        })
    }, 500),

    getTicketName(item) {
      let productName = "이용권"
      switch (item.optionType) {
        case "option1":
          productName = "솔로샷"
          break
        case "option2":
          productName = "솔로샷 듀얼"
          break
        case "option3":
          productName = "그룹샷 " + item.cloth + "명"
          break
        case "option4":
          productName = "2인 할인 팩"
          break
        case "option5":
          productName = "3인 할인 팩"
          break
        case "option6":
          productName = "4인 할인 팩"
          break
      }
      return productName
    },

    getConceptImageSrc(conceptTitle) {
      try {
        console.log("getConceptImageSrc conceptTitle:", conceptTitle)
        if (!conceptTitle || this.concepts.length == 0) return require("@/assets/placeholder.jpg")
        const concept = this.concepts.find((c) => c.title === conceptTitle)
        if (!concept || !concept.pattern_1_image) return require("@/assets/placeholder.jpg")
        console.log("concept:", concept)
        return "/upload/concept/thumbnail_image/" + concept.pattern_1_image
      } catch (e) {
        console.error("getConceptImageSrc error:", e)
        return require("@/assets/placeholder.jpg")
      }
    },

    getShootingTime(time) {
      const [hours, minutes] = time.split(':').map(Number)
      const totalMinutes = hours * 60 + minutes + SHOOTING_TIME
      const endHours = Math.floor(totalMinutes / 60)
      const endMinutes = totalMinutes % 60
      const shootingtime = `${String(endHours).padStart(2, '0')}:${String(endMinutes).padStart(2, '0')}`
      return shootingtime
    },

    openSelectConcept() {
      this.show_concept_select = true
      this.show_concept_select_componentKey++
    },

    changeConcept(newConcept) {
      // 여기에 컨셉 변경 로직을 추가해야 함
      console.log("컨셉 변경 클릭:", newConcept)
      this.selected_reservation.concept = newConcept.title

      this.$http.post("/api/reservation/update/change_concept", {
        concept: newConcept.title,
        id: this.selected_reservation.id,
      }).then((res) => {
        if (res.data.affectedRows) {
          alert("저장하였습니다.")
        } else {
          alert("저장에 실패하였습니다.")
        }
      })
        .catch((e) => {
          console.log(e)
        })
    },
  },
}
</script>
<style scoped>
.timeTable {
  /* width: 100%;
    max-width: 1030px; */
  width: 100%;
  min-width: 1030px;
  max-width: 1280px;
  border: 1px solid #ccc;
  border-collapse: collapse;
  text-align: center;
  font-weight: 500;
}

.timeTable tr td {
  padding: 4px 8px;
  height: 110px;
  cursor: pointer;
  border: 1px solid #ccc;
}

.reservation {
  cursor: pointer;
  background: #ffb19c;
  color: white;
  font-weight: 300;
}

.rest {
  cursor: pointer;
  background: #ccc;
  color: white;
  font-weight: 300;
}

/* 상세보기 */
.table_detail {
  background: white;
  width: 90%;

}


.table_detail tr td {
  padding: 4px;
}

.table_detail tr td:first-child {
  width: 120px;
  padding: 4px;
  white-space: nowrap;
}

.table_detail tr td:last-child {
  width: 100%;
  min-width: 300px;
  padding: 4px 12px;
}
</style>
