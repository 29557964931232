import alert from "@/methods/alert.js"
import commas from "@/methods/commas.js"
import links from "@/methods/links.js"
import storage from "@/methods/storage.js"
import date from "@/methods/date.js"
import formatter from "@/methods/formatter.js"

export default {
  install(Vue) {
    // 얼럿 관련 Methods
    Vue.prototype.$buttonAlert = alert.buttonAlert

    // 콤마 관련 Methods
    Vue.prototype.$inputToComma = commas.inputToComma
    Vue.prototype.$toComma = commas.toComma
    Vue.prototype.$toUnComma = commas.toUnComma

    // 링크 처리 관련 Methods
    Vue.prototype.$linkify = links.linkify

    // 라우터 이동 관련 Methods
    Vue.prototype.$push = function (path) {
      this.$router.push(path)
    }

    Vue.prototype.$imageUrl = storage.imageUrl
    Vue.prototype.$profileImageUrl = storage.profileImageUrl
    Vue.prototype.$updateTimeStamp = storage.updateTimeStamp
    Vue.prototype.$shareLink = links.shareLink
    Vue.prototype.$getTDaysLater = date.getTDaysLater
    Vue.prototype.$formatLikeCount = formatter.formatLikeCount
    Vue.prototype.$qrcodeImageUrl = storage.qrcodeImageUrl
    Vue.prototype.$qrcodeResizedImageUrl = storage.qrcodeResizedImageUrl
  },
}
