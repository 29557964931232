<template>
  <div class="pb-16">
    <!-- 본문 -->
    <v-sheet class="pt-6 mx-auto">
      <ReservationList  />
    </v-sheet>
  </div>
</template>
<script>
import ReservationList from "./ReservationList"

export default {
  components: {
    ReservationList,
  },

  data: () => ({
    //reserveComponentKey: 0,
  }),

  created() {},

  mounted() {},

  methods: {
    load() {},
  },
}
</script>
<style scoped>
::v-deep .v-btn {
  border: 2px solid #ccc !important;
  font-size: 18px;
}
</style>
