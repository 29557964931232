<template>
  <v-dialog max-width="440" :value="visible" content-class="rounded-10" style="z-index: 15" @input="$emit('input', $event)">
    <v-sheet class="rounded-10 px-4 px-md-4 py-4 py-md-10 d-flex flex-column align-center" color="#fffbf2" style="border: 2px solid #bebebe">
      <img src="@/assets/warning.png" class="icon" />

      <!-- 첫번째 페이지 -->
      <div v-if="currentPage === 1" style="width: 100%">
        <div class="text-h6 text-md-h5 text-center black--text text--darken-2 font-weight-bold my-1 my-md-2" style="word-break: keep-all; white-space: normal">{{ $t("dialog.refund-title") }}</div>
        <p class="text-center px-1 text-body-2 text-md-body-1 text-wrap" v-html="$t('dialog.refund-warning-message')"></p>

        <v-card flat width="100%" class="d-flex flex-column align-center py-2 py-md-4" style="border: 2px solid #dadada">
          <v-card-text class="text-center pa-0 text-body-2 text-md-body-1" style="color: #545454; font-size: 16px">{{ $t("dialog.refund-warning-list-1") }}</v-card-text>
          <v-card-text class="text-center pa-0 mt-2 text-body-2 text-md-body-1" style="color: #545454; font-size: 16px">{{ $t("dialog.refund-warning-list-2") }}</v-card-text>
          <v-card-text class="text-center pa-0 mt-2 text-body-2 text-md-body-1" style="color: #545454; font-size: 16px">{{ $t("dialog.refund-warning-list-3") }}</v-card-text>
          <v-card-text class="text-center pa-0 mt-2 text-body-2 text-md-body-1" style="color: #545454; font-size: 16px">{{ $t("dialog.refund-warning-list-4") }}</v-card-text>
        </v-card>
        <v-container class="d-flex flex-start my-1 my-md-2 py-0" fluid>
          <ul class="text-body-2 text-md-body-1 grey--text text--darken-2 text-wrap">
            <li>{{ $t("dialog.refund-warning-list-5") }}</li>
            <li>{{ $t("dialog.refund-warning-list-6") }}</li>
          </ul>
        </v-container>
        <v-container class="d-flex justify-center py-1 py-md-2" v-if="!readonly">
          <span class="grey--text text--darken-2 text-body-2 text-md-body-1">{{ $t("dialog.refund-agree-check-label") }}</span>
          <v-simple-checkbox v-model="isCheck1" color="primary" />
        </v-container>
      </div>

      <!-- 두번째 페이지 -->
      <div v-else-if="currentPage === 2" class="px-0" style="width: 100%">
        <!-- 두번째 페이지 내용 -->
        <div class="text-h6 text-md-h5 text-center black--text text--darken-2 font-weight-bold my-1 my-md-2">{{ $t("dialog.noshow-warning-title") }}</div>
        <div class="text-center px-4 text-body-2 text-md-body-1 text-wrap" v-html="$t('dialog.noshow-warning-message-1', { shootingTime: SHOOTING_TIME })"></div>
        <div class="text-center px-4 text-body-2 text-md-body-1 text-wrap" v-html="$t('dialog.noshow-warning-message-2')"></div>

        <v-img :src="getNoshowGuideImage()" width="90%" max-width="400" class="my-2 mx-auto" aspect-ratio="2.8" contain></v-img>

        <v-container class="d-flex flex-start py-0" fluid>
          <ul class="text-body-2 text-md-body-1 grey--text text--darken-2">
            <li>{{ $t("dialog.noshow-warning-list-1") }}</li>
          </ul>
        </v-container>

        <v-container class="d-flex justify-center py-1 py-md-2" v-if="!readonly">
          <span class="grey--text text--darken-2 text-body-2 text-md-body-1">{{ $t("dialog.noshow-agree-check-label") }}</span>
          <v-simple-checkbox v-model="isCheck2" color="primary" />
        </v-container>
      </div>

      <!-- 세번째 페이지 -->
      <div v-else-if="currentPage === 3" class="px-0" style="width: 100%">
        <!-- 세번째 페이지 내용 -->
        <div class="text-h6 text-md-h5 text-center black--text text--darken-2 font-weight-bold my-1 my-md-2 text-wrap">{{ $t("dialog.composite-warning-title") }}</div>
        <div class="text-center px-4 text-body-2 text-md-body-1 text-wrap" v-html="$t('dialog.composite-warning-message-1')"></div>
        <v-img src="@/assets/reservation/composite_bg.png" width="90%" max-width="400" class="my-1 mx-auto" aspect-ratio="1.9" contain></v-img>

        <v-container class="d-flex flex-start py-0" fluid>
          <ul class="text-body-2 text-md-body-1 grey--text text--darken-2">
            <li>{{ $t("dialog.composite-warning-list-1") }}</li>
            <li>{{ $t("dialog.composite-warning-list-2") }}</li>
          </ul>
        </v-container>

        <v-container class="d-flex justify-center py-1 py-md-2" v-if="!readonly">
          <span class="grey--text text--darken-2 text-body-2 text-md-body-1">{{ $t("dialog.quality-agree-check-label") }}</span>
          <v-simple-checkbox v-model="isCheck3" color="primary" />
        </v-container>
      </div>

      <div class="d-flex justify-center">
        <span class="grey--text text--darken-2 text-body-1"> <v-icon medium>mdi-chevron-left</v-icon>&nbsp;&nbsp;{{ currentPage }} / 3&nbsp;&nbsp;<v-icon medium>mdi-chevron-right</v-icon> </span>
      </div>

      <v-container fluid class="d-flex justify-center py-1 py-md-2">
        <v-btn color="white" class="dialog_btn rounded-2 mr-2 grey--text" width="35%" style="font-size: 20px" depressed @click="prevPage" large>
          {{ currentPage === 1 ? $t("button.back") : $t("button.prev") }}
        </v-btn>

        <v-btn
          color="#D8207E"
          class="dialog_btn rounded-2 ml-2"
          style="font-size: 20px"
          width="35%"
          depressed
          @click="nextPage"
          large
          :disabled="(currentPage === 1 && !isCheck1) || (currentPage === 2 && !isCheck2) || (currentPage === 3 && !isCheck3)"
        >
          {{ currentPage === 3 ? $t("button.ok") : $t("button.next") }}
        </v-btn>
      </v-container>
    </v-sheet>
  </v-dialog>
</template>

<script>
import { SHOOTING_TIME } from "@/constant"
import { mapState } from "vuex"

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isCheck1: false,
    isCheck2: false,
    isCheck3: false,
    currentPage: 1,
  }),
  computed: {
    ...mapState(["lang"]),
    SHOOTING_TIME() {
      return SHOOTING_TIME
    },
  },
  created() {
    if (this.readonly) {
      this.isCheck1 = true
      this.isCheck2 = true
      this.isCheck3 = true
    }
  },
  methods: {
    nextPage() {
      if (this.currentPage === 3) {
        this.$emit("agree")
      } else {
        this.currentPage++
      }
    },
    prevPage() {
      if (this.currentPage === 1) {
        this.$emit("cancel")
      } else {
        this.currentPage--
      }
    },
    getNoshowGuideImage() {
      let fileName = "noshow-guide.png"
      switch (this.lang) {
        case "jp":
          fileName = "noshow-guide-jp.png"
          break
        case "en":
          fileName = "noshow-guide-en.png"
          break
        default:
          fileName = "noshow-guide.png"
          break
      }

      return require(`@/assets/reservation/${fileName}`)
    },
  },
  watch: {
    visible(val) {
      if (!val) {
        this.currentPage = 1
        this.isCheck = false
      }
    },
  },
}
</script>

<style scoped>
.icon {
  width: 30px;
  height: 30px;
}

@media (min-width: 600px) {
  .icon {
    width: 60px;
    height: 60px;
  }
}

.dialog_btn {
  border: 2px solid #dadada !important;
  font-size: 16px;
}
::v-deep .v-btn:not(.v-btn--disabled) {
  color: white;
}

.text-wrap {
  white-space: pre-wrap; /* CSS3 */
  word-wrap: break-word; /* IE */
  word-break: keep-all; /* 긴 단어 처리 */
  max-width: 100%; /* 컨테이너 최대 너비 설정 */
}
</style>
