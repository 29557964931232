<template>
  <div class="container px-6" @click="$emit('click', persons)">
    <div>
      <div class="optionTitle">{{ src.title }}</div>
      <div class="optionSubTitle">{{ src.subTitle }}</div>
    </div>
    <div class="text-body-1 text-md-h6 d-flex flex-column align-center">
      <div>
        <span color="#545454" class="text-caption mr-1">{{ $t("page.reservation.indoor-option-per-peron") }}</span>
        <span class="warning--text">{{ $toComma(src.price) }}</span
        ><span>{{ $t("common.krw") }}</span>
        <v-btn small fab depressed class="pa-0 ml-2" color="white" @click="$emit('click')">
          <v-icon size="34" :color="selected ? 'primary' : 'grey'"> {{ selected ? "mdi-checkbox-marked-circle" : "mdi-checkbox-blank-circle-outline" }}</v-icon>
        </v-btn>
      </div>
      <div class="d-flex align-center text-body-1">
        {{ $t("page.reservation.people-number") }}
        <v-select
          style="width: 72px; margin: 0 6px; z-index: 12"
          class="rounded-10 shrink my-0"
          hide-details
          outlined
          :disabled="!selected"
          dense
          append-icon="mdi-chevron-down"
          :items="select_list"
          v-model.number="persons"
          :key="componentKey + 'person'"
          @change="(e) => $emit('changeCount', e)"
        >
          <template v-slot:selection="{ item }">
            <span class="pl-1 pb-2px">{{ item }}</span>
          </template>
          <template v-slot:append>
            <v-icon style="width: 12px">mdi-chevron-down</v-icon>
          </template>
        </v-select>
        {{ $t("page.reservation.people-unit") }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["selected", "src"],
  data: () => ({
    componentKey: 0,
    select_list: [2, 3, 4],
    persons: 2,
  }),
}
</script>
<style scoped>
.container {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #c2bcba;
}

.optionTitle {
  font-size: 24px;
  font-weight: 400;
  color: #535353;
}
.optionSubTitle {
  font-size: 14px;
  font-weight: 400;
  color: #8d8d8d;
}
</style>
