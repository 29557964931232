<template>
  <div class="container px-6" @click="$emit('click')">
    <div>
      <div class="optionTitle">
        <span>{{  $t("page.reservation.lafia-option-item-c-Title") }}</span>
        <span style="font-size: 18px; margin-left: 4px;">[{{ src.title }}]</span>
      </div>
      <div class="optionSubTitle">{{ src.subTitle }}</div>
      <div class="optionSubTitle">{{ src.subTitle1 }}</div>
    </div>
    <div class="d-flex flex-column justify-center align-end">
      <div class="text-body-1 text-md-h6 d-flex align-center">
        <span class="warning--text">{{ $toComma(src.price) }}</span
        ><span>{{ $t("common.krw") }}</span>
        <v-btn small fab depressed class="pa-0 ml-2" color="white" @click="$emit('click')">
          <v-icon size="34" :color="selected ? 'primary' : 'grey'"> {{ selected ? "mdi-checkbox-marked-circle" : "mdi-checkbox-blank-circle-outline" }}</v-icon>
        </v-btn>
      </div>
      <div class="text-caption text-end" style="color: #545454">
        <div>
          <span class="warning--text text-decoration-line-through">{{ $toComma(origPrice) }}</span
          ><span>{{ $t("common.krw") }}</span> &nbsp;<span>{{ $t("page.reservation.lafia-option-item-discount", { discount: discount }) }}</span>
        </div>
        <div>{{ $t("page.reservation.lafia-option-item-persons", { person: person }) }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["selected", "src", "person", "discount", "origPrice"],
}
</script>
<style scoped>
.container {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #c2bcba;
}

.optionTitle {
  font-size: 24px;
  font-weight: 400;
  color: #535353;
}
.optionSubTitle {
  font-size: 14px;
  font-weight: 400;
  color: #8d8d8d;
}
</style>
