<template>
  <div>
    <v-sheet class="mx-auto">
      <v-sheet v-if="list.length" class="">
        <v-row style="margin: 0">
          <v-col v-for="item in list" :key="item.id" cols="6"
            style="border: 1px solid white; cursor: pointer; padding: 0">
            <v-img width="100%" style="max-height: min(50vw, 290px)" :src="item.thumbnailUrl" @click="openReview(item)">
              <div class="d-flex align-end sela-bottom-caption">
                <div class="d-flex justify-space-between align-center pa-1 text-body-1"
                  style="column-gap: 4px; width: 100%">
                  <v-img class="rounded-circle" style="max-width: 16px" aspect-ratio="1"
                    :src="getProfileImgPath(item.profileImageUrl)"></v-img>
                  <div class="sela-caption text-body-1 font-weight-light ml-1" style="color: white">
                    {{ item.user_id.split("@")[0] }}
                  </div>
                  <div style="flex-grow: 1"></div>
                  <div class="sela-caption text-body-1 font-weight-light" style="color: white">
                    {{ $formatLikeCount(item.like_count) }}
                  </div>
                  <v-icon size="19" color="white" style="align-self: end" @click.stop="toggleLike(item)">
                    mdi-heart-outline </v-icon>
                </div>
              </div>
            </v-img>
          </v-col>
        </v-row>
      </v-sheet>
      <v-sheet v-else min-height="200" class="d-flex justify-center align-center">
        <p style="color: grey">{{ $t("page.conceptbooth.review-is-empty") }}</p>
      </v-sheet>
    </v-sheet>
    <ReviewDialog :visible="showDetail" :item="selected_item" @close="showDetail = false" />
  </div>
</template>

<script>
import ReviewDialog from "./ReviewDialog"

export default {
  components: {
    ReviewDialog,
  },

  props: {
    conceptId: String,
  },

  data: () => ({
    list: [],
    showDetail: false,
    selected_item: null,
  }),

  mounted() {
    this.load()
  },

  methods: {
    load() {
      this.$http
        .post("/api/review/select/concept", {
          params: {
            conceptId: this.$route.query.conceptId || this.conceptId,
          },
        })
        .then((res) => {
          this.list = res.data.map((x) => {
            const extension = x.complete_url.split(".").pop()
            return {
              ...x,
              thumbnailUrl: this.$imageUrl(x.complete_url.replace(`.${extension}`, "_thumbnail.jpg")),
              imageUrl: this.$imageUrl(x.complete_url),
              editable: x.user_id.toLowerCase() == (this.$store.state.lafia_user.user_id || "").toLowerCase(),
              profileImageUrl: this.$profileImageUrl(x.profile_image),
            }
          })
          console.log(this.list)
        })
    },

    openReview(item) {
      this.selected_item = item
      this.showDetail = true
    },

    toggleLike(item) {
      if (!this.$store.state.lafia_user.user_id) {
        alert(this.$t("alert.error_need_authentication"))
        this.$router.push("/auth/login")
        return
      }
      this.$http
        .post("/api/review/update/like", {
          params: {
            reviewId: item.id,
            requestId: item.requestId,
            user_id: this.$store.state.lafia_user.user_id,
          },
        })
        .then((res) => {
          item.like_count = res.data.like_count || 0
          this.list = [...this.list]
        })
    },

    getProfileImgPath(path) {
      return path.includes("/") ? path : "/upload/user/profile_image/" + path
    },
  },
}
</script>
