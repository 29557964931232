<template>
    <v-dialog max-width="440" :value="visible" content-class="rounded-10" style="z-index: 15" @input="$emit('input', $event)">
      <div class="d-flex justify-center">
        <img width="50%" src="@/assets/deco/crown.png" />
      </div>

      <v-sheet class="rounded-10 px-2 px-md-4 py-4 py-md-10 d-flex flex-column align-center" color="#fffbf2" style="border: 2px solid #bebebe">
        <div class="d-flex justify-center">
          <img v-if="iconSrc" :src="iconSrc" :width="$vuetify.breakpoint.mobile ? 70 : 120" style="filter: brightness(0);" />
        </div>  
        <div>
          <div class="text-center font-weight-bold" :style="{ fontSize: $vuetify.breakpoint.mobile ? '26px' : '30px'}">{{ title }}</div>
          <p class="text-center px-1 text-body-2 text-md-body-1" v-html="message" style="color: #737373;"></p>
        </div>
        <div class="d-flex justify-center" style="width: 100%; padding-left: 20px; padding-right: 20px;">
          <v-img :src="centerImage" />
          <!-- <div style="background-color: black; width: 80%; aspect-ratio: 1.2"></div> -->
        </div>
  
        <v-container fluid class="d-flex justify-center mt-4">
          <v-btn class="dialog_btn rounded-2 ml-2" style="font-size: 20px" width="35%" depressed @click="cancelClick" large> {{ $t("button.back") }} </v-btn>
          <v-btn color="primary" class="dialog_btn rounded-2 ml-2" style="font-size: 20px; color: white" width="35%" depressed @click="confirmClick" large> {{ $t("button.do-start") }} </v-btn>
        </v-container>
      </v-sheet>
    </v-dialog>
  </template>
  <script>
  import DialogHeader from "@/views/components/DialogHeader"
  export default {
    components: {
      DialogHeader,
    },
    props: {
      title: {
        type: String,
        default: "",
      },
      message: {
        type: String,
        default: "",
      },
      visible: {
        type: Boolean,
        default: false,
      },
      iconSrc: {
        type: String,
        default: "",
      },
      centerImage: {
        type: String,
        default: "",
      },
    },
  
    methods: {
      cancelClick() {
        this.$emit("cancel")
      },
      confirmClick() {
        this.$emit("confirm")
      },
    },
  }
  </script>
  <style scoped>
  .icon {
    width: 60px;
    height: 60px;
  }
  
  .dialog_btn {
    border: 2px solid #dadada !important;
    font-size: 16px;
  }
  
  ::v-deep .v-btn:not(.v-btn--disabled) {
    color: rgb(71, 71, 71);
  }

  ::v-deep .v-dialog {
    box-shadow: none !important;
}
  </style>
  