<template>
  <div class="pb-16">
    <TitleLogo :title="$t('page.mypage.title')" />

    <!-- 목록 -->
    <v-sheet>
      <!-- 탭 제목 -->
      <v-tabs v-model="tabs" color="primary" fixed-tabs @change="refreshTabContent">
        <v-tab v-for="item in menu" :key="item.title" width="50%" class="text-subtitle-1 font-weight-medium">
          {{ $t(item.title) }}
        </v-tab>
      </v-tabs>
      <v-divider></v-divider>

      <!-- 탭 본문 -->
      <v-tabs-items v-model="tabs">
        <v-tab-item>
          <PurchaseHistory ref="myPurchase" />
        </v-tab-item>
        <v-tab-item> 
          <MyPicture ref="myPicture" /> 
        </v-tab-item>        
      </v-tabs-items>
    </v-sheet>
  </div>
</template>
<script>
import MyPicture from "./components/MyPicture"
import PurchaseHistory from "./components/PurchaseHistory"
import TitleLogo from "@/views/components/TitleLogo"
import { mapMutations, mapState } from "vuex"

export default {
  components: {
    MyPicture,
    TitleLogo,
    PurchaseHistory,
  },

  data: () => ({
    tabs: null,
    menu: [
      {
        title: "page.mypage.tab-title-payHistory",
        path: "/mypage/home?type=reservation",
      },
      {
        title: "page.mypage.tab-title-album",
        path: "/mypage/home?type=picture",
      },
    ],
  }),

  computed: {
    ...mapState(["lafia_user", "home_selected_tab"]),
  },

  created() {
    // 비 로그인 시
    if (!this.lafia_user.is_logined) {
      alert(this.$t("alert.need-auth-menu"))
      this.$router.push("/auth/login")
      return
    }

    const type = this.$route.query.type || this.home_selected_tab

    switch (type) {
      case "reservation": //이용권
        this.tabs = 0
        break
      case "picture": // 공주의 앨범
        this.tabs = 1
        break
    }
  },

  methods: {
    ...mapMutations(["SET_HOME_TAB"]),

    refreshTabContent() {
      switch (this.tabs) {
        case 0:
          this.$refs.myPurchase?.load()
          this.SET_HOME_TAB("reservation")
          break
        case 1:
          this.$refs.myPicture?.load()
          this.SET_HOME_TAB("picture")
          break
        default:
          break
      }
    },
  },
}
</script>
<style scoped>
.banner {
  /* background: url('~@/assets/list_main.svg'); */
  background-size: 100%;
}
.v-tab--active {
  color: black;
}
::v-deep .v-tab {
  padding: 0;
}
</style>
