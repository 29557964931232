<template>
    <ImageGallery :initImages="images" @upload="onUpload" :isPending="isPending" @delete="onDelete" />
</template>

<script>
import ImageGallery from '@/views/admin/components/ImageGallery'

export default {
    name: 'QRCodeGallery',
    components: {
        ImageGallery
    },
    props: {
        selected_reservation: {
            type: Object,
            default: {}
        },
    },
    watch: {
        selected_reservation: {
            handler(newVal) {
                this.refresh();
            },
            deep: true
        }
    },
    data() {
        return {
            images: [],
            filenames: [],
            qrcode: null,
            isPending: false,
        }
    },
    mounted() {
        this.refresh();
    },
    methods: {
        refresh() {
            this.images = [];
            this.filenames = [];
            this.qrcode = null;

            this.isPending = true;
            this.$http
                .post("/api/qrcode/select/byReservation", {
                    params: {
                        user_id: this.selected_reservation.user_id,
                        reservation_id: this.selected_reservation.id,
                    },
                })
                .then((res) => {
                    const qrCodeItem = res.data[0];
                    this.filenames = (qrCodeItem?.filenames?.split(",") || []).filter(filename => filename.length > 0)
                    this.qrcode = qrCodeItem?.qrcode || null
                    if (this.qrcode) {
                        this.images = this.getImages(this.qrcode, this.filenames);
                    }
                })
                .catch((err) => {
                    console.error("Error: ", err)
                })
                .finally(() => {
                    this.isPending = false;
                })
        },

        async onUpload(images) {
            this.isPending = true;
            try {
                if (!this.qrcode) {
                    const qrcode = (await this.$http.post("/api/qrcode/insert", {
                        params: {
                            user_id: this.selected_reservation.user_id,
                            reservation_id: this.selected_reservation.id,
                            title: this.selected_reservation.concept,
                        },
                    })).data.qrcode;
                    this.qrcode = qrcode;
                }

                let filenames = this.filenames;
                const newImages = images.filter(image => !this.filenames.includes(image) && image.file)
                for (const image of newImages) {
                    const formData = new FormData();
                    formData.append("qrcode", this.qrcode);
                    formData.append("images", image.file);
                    filenames = (await this.$http.post("/api/qrcode/photo/insert", formData)).data.filenames
                }

                this.filenames = filenames;
                this.images = this.getImages(this.qrcode, this.filenames);
            } catch (error) {
                console.error("Error: ", error)
            } finally {
                this.isPending = false;
            }
        },

        onDelete(uploadedImages) {
            if (this.qrcode) {
                this.$http.post("/api/qrcode/photo/delete", {
                    params: {
                        qrcode: this.qrcode,
                        filenames: uploadedImages.map(x => x.filename)
                    },
                })
            }
        },

        getImages(qrcode, filenames) {
            return filenames.map(filename => {
                return {
                    url: this.$qrcodeImageUrl(qrcode, filename),
                    thumbnailUrl: this.$qrcodeResizedImageUrl(qrcode, filename),
                    filename: filename,
                    file: null
                }
            })
        }
    }
}
</script>