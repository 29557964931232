<template>
	<div class="gallery-container">
		<!-- 로딩 오버레이 추가 -->
		<div v-if="isPending" class="loading-overlay">
			<v-progress-circular indeterminate color="primary"></v-progress-circular>
		</div>

		<!-- 갤러리 영역 -->
		<div class="gallery-scroll" @dragover.prevent @drop.prevent="handleDrop">
			<div class="gallery-grid">
				<div v-for="(image, index) in images" :key="index" class="image-item">
					<div class="image-wrapper">
						<img :src="image.thumbnailUrl || image.url" :alt="`Image ${index + 1}`">
						<input type="checkbox" v-model="selectedImages" :value="index" class="image-checkbox">
					</div>
				</div>
			</div>
		</div>

		<!-- 버튼 영역 -->
		<div class="button-container">

			<div class="manage-buttons">
				<input type="file" id="gallery-fileInput" multiple accept="image/*" @change="handleFileSelect"
					class="hidden" :disabled="isPending">

				<v-btn depressed class="rounded-lg" width="80" @click="handleAddClick" :disabled="isPending">
					추가
				</v-btn>

				<v-btn depressed class="rounded-lg" width="80" @click="deleteSelected"
					:disabled="isPending || selectedImages.length === 0">
					삭제
				</v-btn>

				<v-btn depressed class="rounded-lg" width="80" @click="selectAll"
					:disabled="isPending || images.length === 0">
					전체선택
				</v-btn>
			</div>

			<v-btn depressed class="rounded-lg" width="80" @click="uploadImages"
				:disabled="isPending || images.filter(x => x.file).length === 0">
				업로드
			</v-btn>

		</div>


	</div>
</template>

<script>
export default {
	name: 'ImageGallery',
	props: {
		imageSize: {
			type: Number,
			default: 100
		},
		maxHeight: {
			type: Number,
			default: 250
		},
		initImages: {
			type: Array,
			default: []
		},
		isPending: {
			type: Boolean,
			default: false
		},
	},
	data() {
		return {
			images: this.initImages,
			selectedImages: [],
		}
	},
	watch: {
		initImages: {
			handler(newImages) {
				this.images = newImages;
				this.selectedImages = [];

			},
			deep: true
		}
	},
	methods: {
		handleAddClick(event) {
			document.getElementById('gallery-fileInput').click()
		},
		handleFileSelect(event) {
			const files = event.target.files
			this.addImages(files)
			event.target.value = null;
		},
		handleDrop(event) {
			const files = event.dataTransfer.files
			this.addImages(files)
			event.dataTransfer.clearData();
		},
		addImages(files) {
			for (const file of files) {
				if (file.type.startsWith('image/')) {
					const reader = new FileReader()
					reader.onload = (e) => {
						this.images.push({
							url: e.target.result,
							file: file
						})
					}
					reader.readAsDataURL(file)
				}
			}
		},
		deleteSelected() {
			this.selectedImages.sort((a, b) => b - a)
			const uploadedImages = this.selectedImages.filter(index => this.images[index].url.startsWith('http')).map(index => this.images[index])
			if (uploadedImages.length > 0) {
				if (!window.confirm('업로드된 이미지를 삭제하시겠습니까?')) {
					return;
				}
			}
			for (const index of this.selectedImages) {
				this.images.splice(index, 1)
			}
			this.selectedImages = []
			this.$emit('delete', uploadedImages)
		},
		uploadImages() {
			this.$emit('upload', this.images)
		},
		selectAll() {
			if (this.selectedImages.length === this.images.length) {
				this.selectedImages = []
			} else {
				this.selectedImages = this.images.map((_, index) => index)
			}
		}
	}
}
</script>

<style scoped>
.gallery-container {
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	height: 100%;
	max-height: v-bind(maxHeight + 'px');
	justify-content: space-between;
}

.button-container {
	display: flex;
	gap: 1rem;
	justify-content: space-between;
}

.upload-btn,
.delete-btn {
	padding: 0.5rem 1rem;
	border: 1px solid #ccc;
	border-radius: 4px;
	cursor: pointer;
}

.delete-btn:disabled {
	opacity: 0.5;
	cursor: not-allowed;
}

.hidden {
	display: none;
}

.gallery-scroll {
	flex: 1;
	overflow-y: auto;
	padding: 1rem;
	border: 1px solid #ccc;
	border-radius: 4px;
	min-height: 100px;
}

.gallery-grid {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(v-bind(imageSize + 'px'), 1fr));
	gap: 0.5rem;
}

.image-item {
	position: relative;
	aspect-ratio: 1;
	width: v-bind(imageSize + 'px');
	height: v-bind(imageSize + 'px');
}

.image-wrapper {
	position: relative;
	width: 100%;
	height: 100%;
}

.image-wrapper img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 4px;
}

.image-checkbox {
	position: absolute;
	top: 0.5rem;
	right: 0.5rem;
	width: 20px;
	height: 20px;
}

.manage-buttons {
	display: flex;
	gap: 0.5rem;
}

.loading-overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(255, 255, 255, 0.7);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1;
}
</style>