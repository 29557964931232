<template>
  <div>
    <v-sheet outlined class="ma-2 pa-2 mb-2 d-flex align-center">
      <span>프로모션 선택 {{ selectedYear }}년</span>
      <v-sheet width="300" class="mx-6">
        <v-select :items="promotions" :item-text="(item) => item.name || item.code" item-value="code" v-model="selectedPromotion"></v-select>
      </v-sheet>
      <!-- <v-btn depressed color="primary">조회하기 </v-btn> -->
    </v-sheet>

    <!-- 본문 -->
    <v-sheet outlined class="pa-2 ma-2">
      <v-data-table :headers="tableHeaders" :items="items" hide-default-footer :items-per-page="-1" :loading="isLoading" loading-text="Loading... Please wait">
        <template v-slot:[`item.date`]="{ item }">
          <span>{{ Number(item.date.split("-")[1]) }}월</span>
        </template>
        <template v-slot:[`item.payed_amount`]="{ item }">
          <span>{{ $toComma(item.payed_amount) }}원</span>
        </template>
        <template v-slot:[`item.cancel_amount`]="{ item }">
          <span>{{ $toComma(item.cancel_amount) }}원</span>
        </template>
        <template v-slot:[`item.total_amount`]="{ item }">
          <span>{{ $toComma(item.total_amount) }}원</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn small depressed color="primary" outlined @click="openDetail(item)">세부내역서</v-btn>
        </template>
      </v-data-table>
    </v-sheet>
  </div>
</template>
<script>
import statusService from "@/api/stats-service"
export default {
  props: {
    selectedYear: {
      type: String,
      default: "2025",
    },
  },
  watch: {
    selectedYear: {
      immediate: true, // 컴포넌트가 생성될 때도 실행
      handler(newVal) {
        this.load()
      },
    },
  },
  data: () => ({
    promotions: [],
    defaultPrice: null,
    selectedPromotion: null,

    isLoading: false,
    items: [],
    totalAmount: 0,
    tableHeaders: [
      { value: "date", text: "날짜" },
      { value: "payed_amount", text: "결제 금액", align: "right" },
      { value: "cancel_amount", text: "취소 금액", align: "right" },
      { value: "total_amount", text: "매출 합계", align: "right" },
      { text: "세부내역", value: "actions", width: "150px", sortable: false },
    ],
  }),
  created() {
    this.loadPromotions()
  },
  watch: {
    selectedPromotion() {
      this.load()
    },
  },
  mounted() {},
  methods: {
    loadPromotions() {
      this.$http.post("/api/promotion/select/search").then((res) => {
        this.defaultPrice = res.data.find((e) => e.code === "default")
        this.promotions = res.data.filter((e) => e.code !== "default").map((e) => ({ ...e, name: e.role ? e.name + `(${e.role})` : e.name }))
      })
    },

    async load() {
      if (!this.selectedPromotion) {
        return
      }

      try {
        this.isLoading = true
        const from = this.dayjs().year(this.selectedYear).startOf("year").format("YYYY-MM-DD")
        const to = this.dayjs().year(this.selectedYear).endOf("year").format("YYYY-MM-DD")
        
        //const from = this.dayjs().startOf("year").format("YYYY-MM-DD")
        //const to = this.dayjs(this.defaultPrice.endAt).format("YYYY-MM-DD")

        const result = await statusService.getPromotionSalesStats(true, from, to, this.selectedPromotion)
        console.log(result)
        this.items = result
        this.totalAmount = this.items.reduce((sum, e) => sum + Number(e.total_amount), 0)
      } catch (e) {
        console.log(e)
        alert("데이터를 가져오지 못했습니다.")
      } finally {
        this.isLoading = false
      }
    },
    openDetail(item) {
      this.$emit("open", { item, code: this.selectedPromotion })
    },
  },
}
</script>
<style></style>
