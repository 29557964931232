<template>
  <v-dialog v-model="show" activator="parent" content-class="sela-dialog" v-if="item">
    <div class="sela-dialog-card" style="height: 85% !important">
      <DialogHeader @close="show = false" />

      <v-img class="sela-dialog-card-content" :src="largeImage" :lazy-src="item.thumbnailUrl" :key="item.imageUrl" contain>
        <FilteredImage v-if="filter" :srcImage="largeImage" :lutUrl="lutUrl(filter)" contain style="position: absolute; left: 0; top: 0; width: 100%; height: 100%" ref="filteredImage" />

        <template v-slot:placeholder>
          <div class="d-flex align-center justify-center fill-height">
            <v-progress-circular color="white" indeterminate></v-progress-circular>
          </div>
        </template>

        <div v-if="loading" class="d-flex align-center justify-center fill-height fill-width" style="background-color: rgba(0, 0, 0, 0.5)">
          <v-progress-circular color="white" indeterminate></v-progress-circular>
        </div>
      </v-img>

      <div class="py-2 px-2" style="overflow-x: auto">
        <div class="inline-block" style="white-space: nowrap">
          <div class="filter-btn" @click="selectFilter(null)">
            <v-img :src="item.imageUrl" />
          </div>
          <div class="filter-btn" v-for="x in list" :key="x.id" @click="selectFilter(x)">
            <FilteredImage :srcImage="smallImage" :lutUrl="lutUrl(x)" />
          </div>
        </div>
      </div>

      <div class="sela-dialog-card-footer" style="justify-content: center; gap: 10px">
        <v-btn outlined class="sela deactivate largeText" @click="show = false" width="25%">{{ $t("button.cancel") }}</v-btn>
        <v-btn outlined class="sela largeText" @click="downloadImage()" width="25%" :disabled="loading">{{ $t("button.save") }}</v-btn>
      </div>
    </div>
  </v-dialog>
</template>
<script>
import DialogHeader from "@/views/components/DialogHeader"
import FilteredImage from "@/views/components/FilteredImage.vue"
import { loadImage, scaleImage } from "@/util/utils"

export default {
  components: {
    DialogHeader,
    FilteredImage,
  },

  props: {
    item: Object,
    visible: Boolean,
  },

  data: () => ({
    list: [],
    largeImage: null,
    smallImage: null,
    filter: null,
    loading: false,
  }),

  computed: {
    show: {
      get() {
        return this.visible
      },
      set(newValue) {
        if (newValue === false) {
          this.$emit("close")
        }
      },
    },

    srcImageUrl: {
      get() {
        //const url = 'http://localhost:3000/upload/auto_photo/selected/25b3131fe50fa79a_1-1717473601960.jpg'//
        const url = this.item.imageUrl
        return url
      },
    },
  },

  async mounted() {
    console.log(this.item)
    const url = this.srcImageUrl
    const srcImage = await loadImage(url)
    this.largeImage = await scaleImage(srcImage, Math.min(window.innerWidth, 580))
    this.smallImage = await scaleImage(srcImage, 48)
    this.$http
      .get("/api/concept/select/bg_filter", {
        params: {
          title: this.item.title,
        },
      })
      .then((res) => {
        this.list = res.data
        console.log(this.list)
      })
  },

  methods: {
    selectFilter(item) {
      this.filter = item
    },

    lutUrl(item) {
      return "https://front.lafia-studio.com/bgfilter/" + item.filename
    },

    async downloadImage() {
      const _makeFilteredImage = async (srcUrl, filterUrl) => {
        return this.$http
          .post("/api/image/filter", {
            params: {
              src_url: srcUrl.startsWith("http") ? srcUrl : window.location.origin + srcUrl,
              filter_url: filterUrl,
            },
          })
          .then((res) => {
            console.log(res.data)
            return res.data.url
          })
      }

      if (this.filter) {
        this.loading = true
        try {
          const filename = (await _makeFilteredImage(this.srcImageUrl, this.lutUrl(this.filter))).split("/").pop()
          console.log(filename)
          console.log("open url", "https://front.lafia-studio.com/imageViewer-v2.html?image=" + filename)
          // window.open("https://front.lafia-studio.com/imageViewer-v2.html?image=" + filename, "_blank")
          const a = document.createElement("a")
          a.href = "https://front.lafia-studio.com/imageViewer-v2.html?image=" + filename
          a.click()
        } catch (e) {
          console.error(e)
          alert("다운로드 중 오류가 발생했습니다. 다시 시도해주세요.")
        }
        this.loading = false
      } else {
        const a = document.createElement("a")
        a.href = this.srcImageUrl
        a.download = this.srcImageUrl.split("/").pop()
        a.click()
      }
    },
  },
}
</script>

<style>
.filter-btn {
  cursor: pointer;
  border-radius: 5px;
  overflow: hidden;
  display: inline-block;
  margin-right: 5px;
}

.filter-btn .v-image {
  width: 48px;
  height: 48px;
  object-fit: cover;
}
</style>