<template>
    <div class="sela-dialog-card-header">
        <div style="width: 25%"></div>
        <div class="d-flex justify-center align-center" style="gap: 16px">            
            <slot name="center-buttons"></slot>
        </div>
        <div style="width: 25%; text-align: right;">
            <v-icon v-if='!hideClose' class="" color="white" size="36" style="cursor: pointer;"
                @click.stop="$emit('close')">
                mdi-close
            </v-icon>
        </div>
    </div>
</template>
<script>
export default {

    props: {
        hideClose: {
            type: Boolean,
            default: false,
        }
    },

}
</script>
<style></style>
